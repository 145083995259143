import React, { useState, useMemo, useEffect } from 'react';
import { Drawer, Row, Col, Empty, message, Modal, Popover, Checkbox, Alert, Button, Form } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Link, useHistory, useParams } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';

import { STRIPE_KEY } from 'config/env';

import { getDifferenceBetweenDate, formatToDateString } from 'utils/date';
import { constructDisplayPrice, getSingularOrPluralLabel, guard } from 'utils/general';
import { build404Uri, buildThankYouUri, buildBaseUri, buildCheckOutUri, buildHomePage } from 'utils/routes';

import { getBookingTotalPriceAfterPromotion, checkAvailabilityAndPricing, postCreateBooking } from 'apis/booking';
import { useGetHostById } from 'apis/host';

import Loading from 'components/FullScreenLoading/FullScreenLoading';
import { withUserContext } from 'contexts/UserContext/UserContext';

import GuestDetailsModal from './components/GuestDetailsModal/GuestDetailsModal';
import RemarksModal from './components/RemarksModal/RemarksModal';
import FormInput from 'components/Input/FormInput/FormInput';
import FormLabel from 'components/FormLabel/FormLabel';

import { LABEL_SIZE_LARGE } from 'utils/constants';
import { LETTER_N_NUMBER } from 'utils/regex';

import NoImagePlaceholder from 'images/no-image-placeholder.jpg';

import {
  BackButton,
  BackIcon,
  BoldRow,
  BookingCard,
  ButtonCol,
  ConfirmBookingButton,
  DatesRow,
  EmptyBookingContainer,
  ImageCol,
  PriceDetailsContainer,
  PriceDetailPopOverContentContainer,
  PriceDetailContentRow,
  PriceRow,
  RemarksIcon,
  RemoveIcon,
  MinusIcon,
  StyledBookingRow,
  StyledBookingImage,
  TotalPriceRow,
  TotalRow,
  CheckBoxRow,
  PromotionCard
} from './BookingDrawer.styles';

/* =============================================== Local Functions =============================================== */

const submitBooking = (hostId, userBooking, guestDetails, paymentMethod, hCaptchaToken, promotionCode) => {
  const { startDate, endDate, units } = userBooking;

  return postCreateBooking(hostId, {
    hCaptchaToken,
    startDate,
    endDate,
    guestDetails,
    units,
    paymentMethod,
    promotionCode
  });
};

const UnitPriceDetails = ({ totalRoomRate, extraCharges }) => {
  return (
    <>
      <PriceDetailContentRow span={24}>
        <span>Room Price</span>
        <span>{constructDisplayPrice(totalRoomRate)}</span>
      </PriceDetailContentRow>
      {!isNaN(extraCharges) && (
        <PriceDetailContentRow span={24}>
          <span>Cleaning Fee</span>
          <span>{constructDisplayPrice(extraCharges)}</span>
        </PriceDetailContentRow>
      )}
    </>
  );
};

const RoomTypePriceDetails = ({ roomPrice, count, extraCharges, extraGuestCharges }) => {
  return (
    <>
      <PriceDetailContentRow span={24}>
        <span>Room Price</span>
        <span>{constructDisplayPrice(roomPrice)}</span>
      </PriceDetailContentRow>
      {!isNaN(extraCharges) && (
        <PriceDetailContentRow span={24}>
          <span>Cleaning Fee</span>
          <span>{constructDisplayPrice(extraCharges)}</span>
        </PriceDetailContentRow>
      )}
      <PriceDetailContentRow span={24}>
        <span>Number of rooms</span>
        <span>{count}</span>
      </PriceDetailContentRow>
      {extraGuestCharges !== 0 && (
        <PriceDetailContentRow span={24}>
          <span>Extra Guest Fee</span>
          <span>{constructDisplayPrice(extraGuestCharges)}</span>
        </PriceDetailContentRow>
      )}
    </>
  );
};

const TotalPriceDetails = ({ totalRoomRate, totalExtraCharges, totalDiscountAmount, totalAmount }) => {
  return (
    <>
      <PriceDetailContentRow span={24}>
        <span>Room Price</span>
        <span>{constructDisplayPrice(totalRoomRate)}</span>
      </PriceDetailContentRow>
      {!isNaN(totalExtraCharges.cleaningFee) && (
        <PriceDetailContentRow span={24}>
          <span>Cleaning Fee</span>
          <span>{constructDisplayPrice(totalExtraCharges.cleaningFee)}</span>
        </PriceDetailContentRow>
      )}
      {!!totalDiscountAmount && (
        <PriceDetailContentRow span={24}>
          <span>Discount</span>
          <span>-({constructDisplayPrice(totalDiscountAmount)})</span>
        </PriceDetailContentRow>
      )}
      <TotalRow span={24}>
        <span>Total</span>
        <span>{constructDisplayPrice(totalAmount)}</span>
      </TotalRow>
    </>
  );
};

const BookingRow = ({ unit, onRemarksButtonClick, onRemoveClick, host, onMinus }) => {
  let {
    adultCapacity,
    childCapacity,
    city,
    state,
    image,
    name,
    extraCharges,
    totalRoomRate,
    total,
    roomTypeName,
    count,
    roomPrice,
    roomTypeCleaningFee
  } = unit;
  const totalExtraGuestFee =
    unit.extraGuest !== null || undefined
      ? unit.extraGuest.number !== 0 && adultCapacity > unit.extraGuest.number
        ? (adultCapacity - unit.extraGuest.number) * unit.extraGuest.amount
        : 0
      : 0;
  const totalPrice = roomTypeCleaningFee
    ? roomTypeCleaningFee.cleaningFee === undefined
      ? totalRoomRate + totalExtraGuestFee
      : totalRoomRate + roomTypeCleaningFee.cleaningFee + totalExtraGuestFee
    : totalRoomRate;
  return (
    <StyledBookingRow gutter={12}>
      <Col span={14}>
        {host.allowBookingEngine && <BoldRow>{name}</BoldRow>}
        {host.allowHotelBookingEngine && <BoldRow>{`${roomTypeName} x ${count}`} </BoldRow>}
        <Row>
          {city}, {state}
        </Row>
        <Row>
          {!!adultCapacity && getSingularOrPluralLabel(adultCapacity, 'adult')}
          {!!adultCapacity && !!childCapacity && ', '}
          {!!childCapacity && getSingularOrPluralLabel(childCapacity, 'child', 'children')}
        </Row>
        {host.allowBookingEngine && (
          <PriceRow>
            {constructDisplayPrice(total)}
            <Popover
              placement="right"
              content={
                <PriceDetailPopOverContentContainer>
                  <UnitPriceDetails totalRoomRate={totalRoomRate} extraCharges={extraCharges} />
                </PriceDetailPopOverContentContainer>
              }
              title="Price Details"
            >
              <PriceDetailsContainer>
                <InfoCircleOutlined />
              </PriceDetailsContainer>
            </Popover>
          </PriceRow>
        )}
        {host.allowHotelBookingEngine && (
          <PriceRow>
            {constructDisplayPrice(totalPrice)}
            <Popover
              placement="right"
              content={
                <PriceDetailPopOverContentContainer>
                  <RoomTypePriceDetails
                    roomPrice={roomPrice}
                    count={count}
                    extraCharges={roomTypeCleaningFee}
                    extraGuestCharges={totalExtraGuestFee}
                  />
                </PriceDetailPopOverContentContainer>
              }
              title="Price Details"
            >
              <PriceDetailsContainer>
                <InfoCircleOutlined />
              </PriceDetailsContainer>
            </Popover>
          </PriceRow>
        )}
        {host.allowBookingEngine && (
          <Row gutter={12}>
            {host.allowHotelBookingEngine && (
              <ButtonCol onClick={onMinus}>
                <MinusIcon />
              </ButtonCol>
            )}
            <ButtonCol onClick={onRemoveClick}>
              <RemoveIcon />
              Remove
            </ButtonCol>
            <ButtonCol onClick={onRemarksButtonClick}>
              <RemarksIcon />
              Remarks
            </ButtonCol>
          </Row>
        )}
      </Col>
      <ImageCol span={9}>
        <StyledBookingImage src={image || NoImagePlaceholder} alt={name} />
      </ImageCol>
      <Col span={1}>
        <ButtonCol onClick={onRemoveClick}>
          <RemoveIcon />
        </ButtonCol>
      </Col>
    </StyledBookingRow>
  );
};

const EmptyBooking = ({ hostId, onCloseDrawer, isHotel = false }) => {
  const linkToMainPage = (
    <Link to={isHotel ? buildHomePage(hostId) : buildBaseUri(hostId)} onClick={onCloseDrawer}>
      Book now!
    </Link>
  );

  return (
    <EmptyBookingContainer>
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>Preparing for a vacation? {linkToMainPage}</span>} />
    </EmptyBookingContainer>
  );
};

const constructExtraChargesDetails = (extraCharges, extraChargesDetails, roomTypeExtraCharges) => {
  const cleaningFee = extraChargesDetails.cleaningFee + extraCharges + roomTypeExtraCharges;

  return { cleaningFee };
};

const constructExtraGuestChargesDetails = (extraChargesDetails, extraGuest, pax) => {
  const extraGuestFee =
    extraGuest !== undefined && extraGuest.number !== 0
      ? (extraChargesDetails.extraGuestFee || 0) + (pax - extraGuest.number) * extraGuest.amount
      : (extraChargesDetails.extraGuestFee || 0) + 0;
  const cleaningFee = extraChargesDetails.cleaningFee;
  return { cleaningFee, extraGuestFee };
};

const calculateUnitsPriceDetails = units => {
  if (!units) {
    return { totalRoomRate: 0, extraChargesDetails: { cleaningFee: 0 } };
  }
  return units.reduce(
    (result, unit) => {
      result.totalRoomRate = result.totalRoomRate + (unit.totalRoomRate || 0);
      if (!!unit.roomTypeCleaningFee) {
        result.extraChargesDetails = constructExtraChargesDetails(0, result.extraChargesDetails, unit.roomTypeCleaningFee);
      } else {
        result.extraChargesDetails = constructExtraChargesDetails(unit.extraCharges, result.extraChargesDetails, 0);
      }
      // result.extraChargesDetails = constructExtraChargesDetails(unit.extraCharges, result.extraChargesDetails, 0);
      if (!!unit.extraGuest) {
        result.extraChargesDetails = constructExtraGuestChargesDetails(result.extraChargesDetails, unit.extraGuest, unit.adultCapacity);
      }
      return result;
    },
    { totalRoomRate: 0, extraChargesDetails: { cleaningFee: 0, extraGuestFee: 0 } }
  );
};

const calculateTotalPriceAfterPromotion = async ({
  hostId,
  units,
  startDate,
  endDate,
  promotionCode,
  totalRoomRate,
  extraChargesDetails,
  handleOnAfterValidatePromotion
}) => {
  let totalPrice = totalRoomRate + extraChargesDetails.cleaningFee + (extraChargesDetails.extraGuestFee || 0);
  let totalDiscountAmount = 0;

  if (promotionCode) {
    const totalPriceAfterPromotion = await getBookingTotalPriceAfterPromotion(hostId, {
      promotionCode,
      unitIds: units.map(unit => unit._id),
      startDate,
      endDate
    }).catch(ex => handleOnAfterValidatePromotion(false, ex.message));

    if (totalPriceAfterPromotion) {
      handleOnAfterValidatePromotion(true);

      totalPrice = totalPriceAfterPromotion.totalAfterPromotion;
      totalDiscountAmount = totalPriceAfterPromotion.finalDiscAmount;
    }
  }

  return { finalPayableAmount: totalPrice, totalDiscountAmount };
};

/* =============================================== Use Function =============================================== */

const usePromotion = form => {
  const [isShowPromotion, setIsShowPromotion] = useState(false);
  const [isConfirmBooking, setIsConfirmBooking] = useState(true);
  const [invalidPromotionCodeMessage, setInvalidPromotionCodeMessage] = useState('');
  const [promotionCode, setPromotionCode] = useState(null);

  const handleOnResetPromotionState = (errorMessage = '') => {
    setIsConfirmBooking(false);
    setInvalidPromotionCodeMessage(errorMessage);
    setPromotionCode('');
  };

  const handleOnClosePromotion = () => {
    setIsShowPromotion(false);
    form.setFieldsValue({
      promotionCode: ''
    });

    handleOnResetPromotionState();
    setIsConfirmBooking(true);
  };

  const handleOnCheckBoxClick = checked => {
    setIsShowPromotion(checked);

    if (!checked) {
      form.setFieldsValue({
        promotionCode: ''
      });

      handleOnResetPromotionState();
      setIsConfirmBooking(true);
    }
  };

  const handleOnChangePromotionCode = promotionCode => {
    if (!!promotionCode) {
      handleOnResetPromotionState();
    } else {
      setPromotionCode('');
      setIsConfirmBooking(true);
    }
  };

  const handleOnApplyPromotionCodeClick = totalRoomRate => async () => {
    const promotionCode = form.getFieldValue('promotionCode');

    setPromotionCode(promotionCode);
  };

  const handleOnAfterValidatePromotion = (isValidPromotion, errorMessage = '') => {
    if (isValidPromotion) {
      setIsConfirmBooking(true);
      setInvalidPromotionCodeMessage('');
    } else {
      handleOnResetPromotionState(errorMessage);
    }
  };

  return {
    isConfirmBooking,
    isShowPromotion,

    invalidPromotionCodeMessage,
    promotionCode,

    handleOnCheckBoxClick,
    handleOnChangePromotionCode,
    handleOnApplyPromotionCodeClick,
    handleOnClosePromotion,
    handleOnAfterValidatePromotion
  };
};

const useCalculateTotalPriceDetails = (hostId, units, startDate, endDate, promotionCode, handleOnAfterValidatePromotion, roomType) => {
  const [finalPayableAmount, setFinalPayableAmount] = useState(0);
  const [totalDiscountAmount, setTotalDiscountAmount] = useState(0);

  const { totalRoomRate, extraChargesDetails } = useMemo(() => calculateUnitsPriceDetails(units), [units]);
  useEffect(() => {
    calculateTotalPriceAfterPromotion({
      hostId,
      units,
      startDate,
      endDate,
      promotionCode,
      totalRoomRate,
      extraChargesDetails,
      handleOnAfterValidatePromotion,
      roomType
    }).then(({ finalPayableAmount, totalDiscountAmount }) => {
      setFinalPayableAmount(finalPayableAmount);
      setTotalDiscountAmount(totalDiscountAmount);
    });
  }, [hostId, units, startDate, endDate, promotionCode, totalRoomRate, extraChargesDetails, handleOnAfterValidatePromotion, roomType]);

  return {
    total: finalPayableAmount,
    totalRoomRate,
    totalExtraCharges: extraChargesDetails,
    totalDiscountAmount: totalDiscountAmount
  };
};

const useFetchHost = () => {
  const { hostId } = useParams();

  const {
    isFetching,
    data: { host },
    error
  } = useGetHostById(hostId);

  const localHost = useMemo(() => guard(() => host, {}), [host]);

  return { isFetching, host: localHost, error };
};

/* =============================================== Main Component =============================================== */

const BookingDrawer = ({
  visible,
  onCloseDrawer,
  hostStripeConnectId,
  userBooking,
  clearBooking,
  removeBookingUnit,
  removeMultipleBookingUnit,
  updateUnitBookingRemarks
}) => {
  const history = useHistory();
  const { hostId } = useParams();
  const [form] = Form.useForm();
  const { host } = useFetchHost();
  const showConfirmBooking = !host.allowHotelBookingEngine;

  const [isConfirmBookingLoading, setIsConfirmBookingLoading] = useState(false);
  const [isGuestDetailsModalVisible, setIsGuestDetailsModalVisible] = useState(false);
  const [isRemarksModalVisible, setIsRemarksModalVisible] = useState(false);
  const [selectedUnitIndex, setSelectedUnitIndex] = useState(-1);

  const { startDate, endDate, units } = userBooking;
  let roomType = [];

  const {
    isConfirmBooking,
    isShowPromotion,

    invalidPromotionCodeMessage,
    promotionCode,

    handleOnCheckBoxClick,
    handleOnChangePromotionCode,
    handleOnApplyPromotionCodeClick,
    handleOnClosePromotion,
    handleOnAfterValidatePromotion
  } = usePromotion(form);

  const { total, totalRoomRate, totalExtraCharges, totalDiscountAmount } = useCalculateTotalPriceDetails(
    hostId,
    units,
    startDate,
    endDate,
    promotionCode,
    handleOnAfterValidatePromotion,
    roomType
  );

  const bookingNights = useMemo(() => getDifferenceBetweenDate(startDate, endDate), [startDate, endDate]);
  const formattedStartDate = useMemo(() => formatToDateString(startDate, '/'), [startDate]);
  const formattedEndDate = useMemo(() => formatToDateString(endDate, '/'), [endDate]);
  const isSkipStripePayment = useMemo(() => total < 2, [total]);

  const handleOnConfirmBookingClick = () => {
    setIsGuestDetailsModalVisible(true);
  };

  const handleOnConfirmBookingClose = () => {
    setIsGuestDetailsModalVisible(false);
  };

  const handleOnConfirmBooking = hostId => async () => {
    const availability = await checkAvailabilityAndPricing(hostId, {
      units: units.map(unit => unit._id.toString()),
      startDate,
      endDate
    }).catch(ex => handleOnAfterValidatePromotion(false, ex.message));
    // console.log(518, availability);
    if (availability.isUnitAvailable) {
      onCloseDrawer();
      const propertyId = window.location.href.split('property=').pop();
      history.push(buildCheckOutUri(hostId, propertyId));
    } else {
      message.error('Sorry! Room sold out! Please select a new room!');
    }
  };

  const handleOnConfirmBookingSubmit = ({ hCaptchaToken, paymentMethod, ...guestDetails }) => {
    setIsConfirmBookingLoading(true);

    submitBooking(hostId, userBooking, guestDetails, paymentMethod, hCaptchaToken, promotionCode)
      .then(async ({ confirmationCodes, paymentSession }) => {
        clearBooking();
        onCloseDrawer();
        handleOnClosePromotion();
        if (!!paymentSession) {
          const stripe = await loadStripe(STRIPE_KEY);
          stripe
            .redirectToCheckout({
              sessionId: paymentSession.id
            })
            .then(result => {
              if (!!result.error) {
                throw new Error(result.error);
              }
            });
        } else {
          // Stripe payment error if payment lower than RM2
          if (isSkipStripePayment) {
            history.push(`${buildThankYouUri(hostId)}?confirmationCodes=${confirmationCodes.join(',')}&totalAfterDiscount=${total}`);
          } else {
            history.push(`${buildThankYouUri(hostId)}?confirmationCodes=${confirmationCodes.join(',')}`);
          }
        }
      })
      .catch(ex => {
        let errorMessage = 'Something went wrong when creating your booking. Please try again later or contact our support.';

        if (ex.code === 404) {
          errorMessage = 'The host selected does not exist.';
          history.push(build404Uri());
        } else if (ex.code === '40002') {
          clearBooking();
          onCloseDrawer();
          handleOnClosePromotion();
          errorMessage = 'The start date is set before today, please select a valid date and try again.';
        }

        message.error(errorMessage);
        message.error('Please refresh our page');
        console.error(ex);
      })
      .finally(() => {
        setIsConfirmBookingLoading(false);
      });
  };

  const handleOnRemarksButtonClick = index => () => {
    setSelectedUnitIndex(index);
    setIsRemarksModalVisible(true);
  };

  const handleOnRemarksClose = () => {
    setSelectedUnitIndex(-1);
    setIsRemarksModalVisible(false);
  };

  const handleOnRemarksConfirm = remarks => {
    updateUnitBookingRemarks(selectedUnitIndex, remarks);
  };

  const handleOnRemoveUnit = (index, hotel, length) => () => {
    if (hotel) {
      if (length > 1) {
        Modal.confirm({
          title: 'Are you sure you want to remove a room from your booking?',
          onOk: () => removeBookingUnit(index),
          okText: 'Yes',
          cancelText: 'No'
        });
      } else {
        Modal.confirm({
          title: 'Are you sure you want to remove this listing from your booking?',
          onOk: () => removeBookingUnit(index),
          okText: 'Yes',
          cancelText: 'No'
        });
      }
    } else {
      Modal.confirm({
        title: 'Are you sure you want to remove this listing from your booking?',
        onOk: () => removeBookingUnit(index),
        okText: 'Yes',
        cancelText: 'No'
      });
    }

    handleOnClosePromotion();
  };

  const handleOnRemoveMultipleUnit = (index, count) => () => {
    Modal.confirm({
      title: 'Are you sure you want to remove this listing from your booking?',
      onOk: () => removeMultipleBookingUnit(index, count),
      okText: 'Yes',
      cancelText: 'No'
    });
    handleOnClosePromotion();
  };

  let rt = [];
  if (units !== undefined) {
    for (let i = 0; i < units.length; i++) {
      if (roomType.length === 0) {
        roomType.push(units[i]);
      } else {
        for (let j = 0; j < roomType.length; j++) {
          if (
            roomType.some(element => {
              if (units[i].roomTypeId === element.roomTypeId) return true;
              return false;
            })
          ) {
          } else {
            roomType.push(units[i]);
          }
        }
      }
    }
  }

  for (let num = 0; num < roomType.length; num++) {
    let count = 0;
    if (
      units.every(element => {
        if (roomType[num].roomTypeId === element.roomTypeId) count++;
        return true;
      })
    ) {
      const formattedRT = {
        _id: roomType[num]._id,
        adultCapacity: roomType[num].adultCapacity, //consider remove count if user need to get room by just the search capacity
        childCapacity: roomType[num].childCapacity,
        city: roomType[num].city,
        image: roomType[num].image,
        name: roomType[num].name,
        roomPrice: roomType[num].totalRoomRate,
        total: roomType[num].total * count,
        totalRoomRate: roomType[num].totalRoomRate * count,
        extraCharges: roomType[num].extraCharges,
        extraGuest: roomType[num].extraGuest,
        state: roomType[num].state,
        roomTypeId: roomType[num].roomTypeId,
        roomTypeName: roomType[num].roomTypeName,
        roomTypeCleaningFee: roomType[num].roomTypeCleaningFee,
        count: count
      };
      rt.push(formattedRT);
    }
  }
  return (
    <>
      <Loading isLoading={isConfirmBookingLoading} />
      <Drawer visible={visible} closable={false} bodyStyle={{ padding: 0 }} width={window.innerWidth >= 768 ? 520 : '100%'} onClose={onCloseDrawer}>
        <BackButton align="middle" onClick={onCloseDrawer}>
          <BackIcon />
          My Booking Summary
        </BackButton>
        {(userBooking && Object.keys(userBooking).length) > 0 ? (
          <>
            <DatesRow gutter={16}>
              <Col span={16}>
                <BoldRow>Dates</BoldRow>
                {formattedStartDate} - {formattedEndDate}
              </Col>
              <Col span={8}>
                <BoldRow>Nights</BoldRow>
                {bookingNights}
              </Col>
            </DatesRow>
            <BookingCard>
              {host.allowBookingEngine &&
                units.map((unit, index) => (
                  <BookingRow
                    key={unit._id}
                    unit={unit}
                    host={host}
                    onRemarksButtonClick={handleOnRemarksButtonClick(index)}
                    onRemoveClick={handleOnRemoveUnit(index, false)}
                  />
                ))}
              {host.allowHotelBookingEngine &&
                rt.map((room, index) => (
                  <BookingRow
                    key={room._id}
                    unit={room}
                    host={host}
                    onRemarksButtonClick={handleOnRemarksButtonClick(index)}
                    onRemoveClick={handleOnRemoveUnit(index, true, room.count)}
                    onMinus={handleOnRemoveUnit(index, true, room.count)}
                  />
                ))}
              {showConfirmBooking && (
                <TotalPriceRow>
                  <TotalPriceDetails
                    totalRoomRate={totalRoomRate}
                    totalExtraCharges={totalExtraCharges}
                    totalDiscountAmount={totalDiscountAmount}
                    totalAmount={total}
                  />
                </TotalPriceRow>
              )}
              {showConfirmBooking && (
                <CheckBoxRow>
                  <Checkbox onChange={e => handleOnCheckBoxClick(e.target.checked)}>Apply promotion code?</Checkbox>
                </CheckBoxRow>
              )}
              {isShowPromotion && (
                <PromotionCard>
                  <Row gutter={[0, 8]}>
                    <Col span={24}>
                      <FormLabel labelSize={LABEL_SIZE_LARGE}>Promotion Code</FormLabel>
                    </Col>
                    <Col span={24}>
                      <Alert message={<span>Please apply your promotion code before confirming the booking.</span>} type="warning" showIcon />
                    </Col>
                    {invalidPromotionCodeMessage && (
                      <Col span={24}>
                        <Alert message={<span>{invalidPromotionCodeMessage}</span>} type="error" showIcon />
                      </Col>
                    )}
                    <Col span={24}>
                      <Form form={form}>
                        <FormInput
                          name="promotionCode"
                          placeholder="Apply your code"
                          onChange={handleOnChangePromotionCode}
                          extraRules={[{ pattern: LETTER_N_NUMBER, message: 'Combination of Numbers and Letters only' }]}
                          uppercase
                        />
                      </Form>
                    </Col>
                    <Col span={24}>
                      <Button type="primary" block onClick={handleOnApplyPromotionCodeClick(totalRoomRate)} disabled={isConfirmBooking}>
                        Apply
                      </Button>
                    </Col>
                  </Row>
                </PromotionCard>
              )}
              {showConfirmBooking && (
                <ConfirmBookingButton type="primary" onClick={handleOnConfirmBookingClick} disabled={!isConfirmBooking}>
                  confirm my booking
                </ConfirmBookingButton>
              )}
              {host.allowHotelBookingEngine && (
                <ConfirmBookingButton type="primary" onClick={onCloseDrawer} disabled={!isConfirmBooking}>
                  Add another Room
                </ConfirmBookingButton>
              )}
              {host.allowHotelBookingEngine && (
                <ConfirmBookingButton type="primary" onClick={handleOnConfirmBooking(hostId)} disabled={!isConfirmBooking}>
                  Book Now
                </ConfirmBookingButton>
              )}
            </BookingCard>
            <GuestDetailsModal
              visible={isGuestDetailsModalVisible}
              hostStripeConnectId={hostStripeConnectId}
              onCancel={handleOnConfirmBookingClose}
              onSubmit={handleOnConfirmBookingSubmit}
              isSkipStripePayment={isSkipStripePayment}
            />
            <RemarksModal
              visible={isRemarksModalVisible}
              unit={units[selectedUnitIndex]}
              onCancel={handleOnRemarksClose}
              onConfirm={handleOnRemarksConfirm}
              isHotel={host.allowHotelBookingEngine}
            />
          </>
        ) : (
          <EmptyBooking hostId={hostId} onCloseDrawer={onCloseDrawer} isHotel={host.allowHotelBookingEngine} />
        )}
      </Drawer>
    </>
  );
};

export default withUserContext(BookingDrawer);
