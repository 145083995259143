import React from 'react';
import { Col, Form, Modal, Row } from 'antd';
import { BankOutlined, CreditCardOutlined, AlipayOutlined } from '@ant-design/icons';
import GrabPayLogo from 'images/logo-grabpay.png';

import FormHCaptcha from 'components/HCaptcha/FormHCaptcha/FormHCaptcha';
import FormInput from 'components/Input/FormInput/FormInput';
import FormRadio from 'components/Radio/FormRadio/FormRadio';

import { LABEL_SIZE_LARGE } from 'utils/constants';

import {
  ButtonContainer,
  InputContainer,
  PaymentTypeRadio,
  PaymentTypeRadioIcon,
  PaymentTypeRadioText,
  SubmitButton,
  StyledBookingImage
} from './GuestDetailsModal.styles';

const { useForm } = Form;

const GuestDetailsModal = ({ visible, hostStripeConnectId, onCancel, onSubmit, isSkipStripePayment }) => {
  const [form] = useForm();

  const handleOnSubmit = values => {
    // form.resetFields(['hCaptchaToken']);
    onSubmit(values);
    onCancel();
  };

  const handleVerificationSuccess = hCaptchaToken => {
    form.setFieldsValue({ hCaptchaToken });
  };

  return (
    <Modal visible={visible} title="Guest Information" footer={null} onCancel={onCancel} destroyOnClose>
      <Form form={form} onFinish={handleOnSubmit}>
        <InputContainer>
          <Row gutter={8}>
            <Col span={12}>
              <FormInput
                name="firstName"
                label="First Name"
                labelSize={LABEL_SIZE_LARGE}
                placeholder="Enter your first name"
                requiredErrorMessage="Please enter your first name"
              />
            </Col>
            <Col span={12}>
              <FormInput
                name="lastName"
                label="Last Name"
                labelSize={LABEL_SIZE_LARGE}
                placeholder="Enter your last name"
                requiredErrorMessage="Please enter your last name"
              />
            </Col>
          </Row>
        </InputContainer>
        <InputContainer>
          <FormInput
            name="icNo"
            label="IC/Passport No."
            labelSize={LABEL_SIZE_LARGE}
            placeholder="Enter your IC/Passport No."
            requiredErrorMessage="Please enter your IC/Passport No"
          />
        </InputContainer>
        <InputContainer>
          <FormInput
            name="email"
            label="Email"
            labelSize={LABEL_SIZE_LARGE}
            placeholder="Enter your email address"
            requiredErrorMessage="Please enter your email address"
            isEmail
          />
        </InputContainer>
        <InputContainer>
          <FormInput
            name="contactNo"
            label="Contact No."
            labelSize={LABEL_SIZE_LARGE}
            placeholder="Enter your contact number"
            requiredErrorMessage="Please enter your contact number"
            isContactNumber
          />
        </InputContainer>
        {false && <FormHCaptcha name="hCaptchaToken" requiredErrorMessage="Please complete the captcha" onVerify={handleVerificationSuccess} />}
        {hostStripeConnectId && !isSkipStripePayment && (
          <FormRadio
            name="paymentMethod"
            label="Select your payment method"
            labelSize={LABEL_SIZE_LARGE}
            selections={[
              {
                value: 'card',
                label: (
                  <PaymentTypeRadio>
                    <PaymentTypeRadioIcon>
                      <BankOutlined />
                    </PaymentTypeRadioIcon>
                    <PaymentTypeRadioText>credit card</PaymentTypeRadioText>
                  </PaymentTypeRadio>
                )
              },
              {
                value: 'fpx',
                label: (
                  <PaymentTypeRadio>
                    <PaymentTypeRadioIcon>
                      <CreditCardOutlined />
                    </PaymentTypeRadioIcon>
                    <PaymentTypeRadioText>fpx</PaymentTypeRadioText>
                  </PaymentTypeRadio>
                )
              },
              {
                value: 'alipay',
                label: (
                  <PaymentTypeRadio>
                    <PaymentTypeRadioIcon>
                      <AlipayOutlined />
                    </PaymentTypeRadioIcon>
                    <PaymentTypeRadioText>Alipay</PaymentTypeRadioText>
                  </PaymentTypeRadio>
                )
              },
              {
                value: 'grabpay',
                label: (
                  <PaymentTypeRadio>
                    <PaymentTypeRadioIcon>
                      <StyledBookingImage src={GrabPayLogo} alt={'grabpay'} />
                    </PaymentTypeRadioIcon>
                    <PaymentTypeRadioText>GrabPay</PaymentTypeRadioText>
                  </PaymentTypeRadio>
                )
              }
            ]}
            type="button"
            requiredErrorMessage="Please select a payment type"
          />
        )}
        <ButtonContainer>
          <SubmitButton type="primary" htmlType="submit">
            checkout
          </SubmitButton>
        </ButtonContainer>
      </Form>
    </Modal>
  );
};

export default GuestDetailsModal;
