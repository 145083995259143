import React from 'react';
import { useHistory } from 'react-router-dom';
import { Empty, Row } from 'antd';

import { buildPropertyBaseUri } from 'utils/routes';

import Image from 'components/Image/BareImage/BareImage';
import { FieldTimeOutlined } from '@ant-design/icons';

import {
  CardContainer,
  StyledCard,
  StyledCardImageContainer,
  StyledEmpty,
  StyledTextUnitAddress,
  StyledTextUnitName,
  StyledTextAvailability
} from './Body.styles';

const CardHeader = ({ image, onClick, availability }) => {
  return (
    <StyledCardImageContainer>
      <Image image={image} alt="View of the property" onClick={onClick} />
    </StyledCardImageContainer>
  );
};

const CardBody = ({ unitName, unitState, onClick, availability }) => {
  const unitAddress = `${unitState}`;

  return (
    <div onClick={onClick}>
      <StyledTextUnitName>{unitName}</StyledTextUnitName>
      <StyledTextUnitAddress>{unitAddress}</StyledTextUnitAddress>
      {availability !== undefined && (
        <StyledTextAvailability>
          {availability === 0 ? (
            <div>
              <FieldTimeOutlined />
              {`\t Sold Out!`}
            </div>
          ) : availability === 1 ? (
            `${availability} more room left!`
          ) : (
            `${availability} more rooms left!`
          )}
        </StyledTextAvailability>
      )}
    </div>
  );
};

const Body = ({ stateMYSelection, hostId, properties = [], filterState }) => {
  const history = useHistory();

  const handleOnPropertyClick = propertyId => () => {
    // console.log(buildPropertyBaseUri(hostId, propertyId));
    history.push(buildPropertyBaseUri(hostId, propertyId));
  };
  let props = [];

  if (filterState === undefined) {
    props = properties.filter(property => {
      if (property.bookingEngine && property.bookingEngine.propSync && property.bookingEngine.propSync === true) {
        return true;
      } else if (property.bookingEngine && property.bookingEngine.propSync === undefined) {
        return true;
      }
      return false;
    });
  } else {
    props = properties
      .filter(property => property.state === filterState)
      .filter(property => {
        if (property.bookingEngine && property.bookingEngine.propSync && property.bookingEngine.propSync === true) {
          return true;
        } else if (property.bookingEngine && property.bookingEngine.propSync === undefined) {
          return true;
        }
        return false;
      });
  }

  return props.length > 0 ? (
    <Row>
      {props.map(property => {
        const propertyId = property._id;
        const firstImage = property.images.length > 0 ? property.images[0].imageUrl : undefined;
        const propertyName = property.bookingEngine ? property.bookingEngine.displayName : property.name;
        const propertyState = stateMYSelection.find(state => state.value === property.state).label;
        const availability = property.totalAvailability;

        return (
          <CardContainer key={propertyId} span={24} md={8} xl={8}>
            <StyledCard cover={<CardHeader image={firstImage} onClick={handleOnPropertyClick(propertyId)} />}>
              <CardBody unitName={propertyName} unitState={propertyState} onClick={handleOnPropertyClick(propertyId)} availability={availability} />
            </StyledCard>
          </CardContainer>
        );
      })}
    </Row>
  ) : (
    <StyledEmpty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No properties available based on your search" />
  );
};

export default Body;
