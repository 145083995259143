import React, { useState, useMemo, useRef } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { Empty, Row, Col, Form, Carousel, message, Button, Card } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useGetUnitDetails } from 'apis/listing';

import { constructDisplayPrice } from 'utils/general';
import { getDifferenceBetweenDate } from 'utils/date';
import { build404Uri, buildHomePage, buildPropertyBaseUri } from 'utils/routes';
// import { REGEX_NOT_NUMBER_N_DOT } from 'utils/constants';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { MainContainer, StyledRoomTypeCarousel } from '../../../components/Image/ImageCarousel/ImageCarousel.styles';
import {
  ImageContainer,
  NextArrowContainer,
  PrevArrowContainer,
  StyledCloseButton,
  StyledImage,
  StyledModal
} from '../../../components/Image/ImageGallery/ImageGallery.styles';
import Image, { NoImage } from '../../../components/Image/BareImage/BareImage';
import RoomInfo, { ReadMore } from './components/RoomInfo/RoomInfo';
import SectionDivider from './components/SectionDivider/SectionDivider';
// import FormInputNumber from '../../../components/InputNumber/FormInputNumber/FormInputNumber';

import {
  AlreadyBookedIcon,
  AlreadyBookedRow,
  CardContainer,
  StyledButton,
  StyledCard,
  StyledEmpty,
  StyledTextUnitName,
  StyledTextPax,
  StyledTextUnitPrice,
  ShowDetailsRow,
  MoreDetailsIcon,
  LessDetailsIcon,
  ShareAltOutlinedIcon,
  StyledNumberSelection,
  StyledTextPropertyDetails,
  BackButton
} from './HotelBody.styles';

const useFetchUnitDetails = unitId => {
  const { hostId } = useParams();
  const history = useHistory();

  const handleUnitDetailsError = () => {
    history.push(build404Uri());
  };

  const { data } = useGetUnitDetails(hostId, unitId, handleUnitDetailsError);

  const unit = useMemo(() => data.unit || {}, [data]);

  return unit;
};

const CardBody = ({
  unitId,
  roomTypeId,
  unitName,
  unitPricePerNight,
  unitAdultPax,
  unitChildPax,
  images,
  unitLength,
  minNight,
  maxNight,
  isUnitBooked,
  onClickAddUnit,
  onClickAddMultipleUnits,
  checkInDate,
  checkOutDate,
  pax
}) => {
  const unitPax = `Total Pax: ` + (unitAdultPax + unitChildPax).toString();

  const [form] = Form.useForm();
  let amenityList = [];

  const unit = useFetchUnitDetails(unitId);

  const noBedroom = `Bedrooms: ${unit.noOfBedrooms}`;
  const noBathRoom = `Bathrooms: ${unit.noOfBathrooms}`;
  // const minimum = `Minimum Night: ${minNight} Night(s)`;
  // const maximum = `Maximum Night: ${maxNight} Night(s)`;

  const bookingNights = useMemo(() => getDifferenceBetweenDate(checkInDate, checkOutDate), [checkInDate, checkOutDate]);

  const [isImageGalleryVisible, setIsImageGalleryVisible] = useState(false);

  const [isShowMoreDetails, setIsShowMoreDetails] = useState(false);

  // let adultSelection = [];
  // let childSelection = [];
  let roomSelection = [];

  // for (let i = 0; i < unitAdultPax + 1; i++) {
  //   adultSelection.push(i);
  // }
  for (let j = 0; j < unitLength; j++) {
    roomSelection.push(j + 1);
  }
  // let adultMap = adultSelection.map(a => ({ value: a, label: a }));
  // childSelection = adultSelection;
  // childSelection.pop();
  // let childMap = childSelection.map(c => ({ value: c, label: c }));
  const roomMap = roomSelection.map(r => ({ value: r, label: r }));

  const toggleShowMoreDetails = () => {
    setIsShowMoreDetails(!isShowMoreDetails);
  };

  const handleOnClickImage = () => {
    setIsImageGalleryVisible(true);
  };

  const handleOnCloseImageGallery = () => {
    setIsImageGalleryVisible(false);
  };

  const handleOnBookButtonClick = e => {
    form
      .validateFields()
      .then(values => {
        // const adultPax = values.adultPax;
        // const childPax = values.childPax;
        const rooms = values.rooms;
        const totalPax = unitAdultPax + unitChildPax;
        if ((bookingNights >= minNight && bookingNights <= maxNight && bookingNights > 0) || !minNight || !maxNight) {
          if (pax <= totalPax && pax !== 0) {
            if (images.length > 0) {
              onClickAddMultipleUnits(roomTypeId, rooms, pax, 0, images[0].imageUrl);
            } else {
              onClickAddMultipleUnits(roomTypeId, rooms, pax, 0);
            }
          } else {
            const warningMessage = 'Search with the number of guest to get more accurate price deals';
            message.warning(warningMessage);
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            });
          }
        } else if (bookingNights === 0) {
          const warningMessage = 'Search with your stay period to get more accurate price deals';
          message.warning(warningMessage);
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        } else {
          const errorMessage =
            bookingNights < minNight
              ? 'Booking Night is lesser than minimum night of selected room, please reselect dates to book'
              : bookingNights > maxNight
              ? 'Booking Night has exceeded maximum booking night of selected room, please reselect dates to book'
              : '';
          // const errorMessage = `${prefixErrorMessage}${baseErrorMessage}`;
          message.warning(errorMessage);
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        }
      })
      .catch(e => {
        console.log('Error', e);
      });
  };

  const PrevArrow = ({ onClick }) => (
    <PrevArrowContainer>
      <LeftOutlined onClick={onClick} />
    </PrevArrowContainer>
  );

  const NextArrow = ({ onClick }) => (
    <NextArrowContainer>
      <RightOutlined onClick={onClick} />
    </NextArrowContainer>
  );

  const BookingDetailsForm = () => {
    return (
      <Form
        form={form}
        initialValues={{
          unitAdultPax,
          unitChildPax,
          unitLength
        }}
      >
        <Row>
          <Col md={12}>
            <StyledTextUnitPrice>{`${constructDisplayPrice(unitPricePerNight)} / Night`}</StyledTextUnitPrice>

            <StyledTextPax>{unitPax}</StyledTextPax>
            <StyledTextPax>{noBedroom}</StyledTextPax>
            <StyledTextPax>{noBathRoom}</StyledTextPax>
            {/* {minNight ? <StyledTextPax>{minimum}</StyledTextPax> : null}
            {maxNight ? <StyledTextPax>{maximum}</StyledTextPax> : null} */}
          </Col>
          <Col md={2}>
            {/* <StyledNumberSelection
              name="adultPax"
              selections={adultMap}
              placeholder="Adults"
              filterOption={null}
              requiredErrorMessage="Please select a value"
            /> */}
          </Col>
          <Col md={2}>
            {/* <StyledNumberSelection
              name="childPax"
              selections={childMap}
              placeholder="Children"
              filterOption={null}
              requiredErrorMessage="Please select a value"
            /> */}
          </Col>
          <Col md={8}>
            <StyledNumberSelection
              name="rooms"
              selections={roomMap}
              placeholder="Rooms"
              filterOption={null}
              requiredErrorMessage="Please select a value"
            />
          </Col>
        </Row>
      </Form>
    );
  };

  const CardFooter = ({ isUnitBooked, onClickAddUnit, totalPax }) => {
    return isUnitBooked ? (
      <AlreadyBookedRow justify="center" align="middle">
        <AlreadyBookedIcon />
        Added to booking
      </AlreadyBookedRow>
    ) : (
      <StyledButton type="primary" onClick={handleOnBookButtonClick}>
        add to booking
      </StyledButton>
    );
  };

  // const Description = ({ description }) => {
  //   return (
  //     <>
  //       <TitleRow>About this space</TitleRow>
  //       <DescriptionRow>{description}</DescriptionRow>
  //     </>
  //   );
  // };

  const { amenities, sleepingArrangements } = unit;

  if (amenities) {
    amenityList = amenities.split(', ');
  }
  if (amenityList.length > 0) {
    return (
      <div>
        <Row gutter={{ md: 48 }}>
          <Col span={24} md={8}>
            <MainContainer>
              <StyledRoomTypeCarousel>
                {images && images.length > 0 ? (
                  images.slice(0, 1).map(image => <Image key={image} image={image.imageUrl} alt={unitName} onClick={handleOnClickImage} />)
                ) : (
                  <NoImage alt={unitName} onClick={handleOnClickImage} />
                )}
              </StyledRoomTypeCarousel>
            </MainContainer>
          </Col>
          <Col span={24} md={16}>
            <Row>
              <Col md={20}>
                <StyledTextUnitName>{unitName}</StyledTextUnitName>
              </Col>
            </Row>
            <SectionDivider />
            <BookingDetailsForm />
            <Row>
              <Col md={12}>
                <>
                  <span onClick={toggleShowMoreDetails} className="see-or-hide">
                    {isShowMoreDetails ? (
                      <ShowDetailsRow>
                        <LessDetailsIcon />
                        {`Hide Details`}
                      </ShowDetailsRow>
                    ) : (
                      <ShowDetailsRow>
                        <MoreDetailsIcon />
                        {`See Details`}
                      </ShowDetailsRow>
                    )}
                  </span>
                </>
              </Col>
              <Col md={4}></Col>
              <Col md={8}>
                <div>
                  <CardFooter isUnitBooked={isUnitBooked} onClickAddUnit={onClickAddUnit} totalPax={unitAdultPax + unitChildPax} />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        {isShowMoreDetails && (
          <Row>
            <SectionDivider />
            <Col span={24} md={24}>
              <RoomInfo amenities={amenityList} sleepingArrangements={sleepingArrangements} />
            </Col>
          </Row>
        )}
        {isImageGalleryVisible && (
          <StyledModal
            width="90%"
            closeIcon={<StyledCloseButton onClick={handleOnCloseImageGallery} />}
            centered
            visible={isImageGalleryVisible}
            onCancel={handleOnCloseImageGallery}
            footer={null}
          >
            <div>
              <Carousel swipeToSlide={true} infinite={true} arrows={true} prevArrow={<PrevArrow />} nextArrow={<NextArrow />}>
                {images.length > 0 &&
                  images.map((photo, index) => (
                    <ImageContainer key={index}>
                      <StyledImage alt={`unit-detail-${index}`} src={photo.imageUrl} />
                      <span>
                        <StyledTextPropertyDetails>{photo.caption[0].text}</StyledTextPropertyDetails>
                      </span>
                    </ImageContainer>
                  ))}
              </Carousel>
            </div>
          </StyledModal>
        )}
      </div>
    );
  } else {
    return (
      <div>
        <Row gutter={{ md: 48 }}>
          <Col span={24} md={8}>
            <MainContainer>
              <StyledRoomTypeCarousel>
                {images && images.length > 0 ? (
                  images.slice(0, 1).map(image => <Image key={image} image={image.imageUrl} alt={unitName} onClick={handleOnClickImage} />)
                ) : (
                  <NoImage alt={unitName} onClick={handleOnClickImage} />
                )}
              </StyledRoomTypeCarousel>
            </MainContainer>
          </Col>
          <Col span={24} md={16}>
            <Row>
              <Col md={20}>
                <StyledTextUnitName>{unitName}</StyledTextUnitName>
              </Col>
            </Row>
            <SectionDivider />
            <BookingDetailsForm adultPax={unitAdultPax} rooms={unitLength} />
            <Row>
              <Col md={12}>
                <>
                  <span onClick={toggleShowMoreDetails} className="see-or-hide">
                    {isShowMoreDetails ? (
                      <ShowDetailsRow>
                        <LessDetailsIcon />
                        {`Hide Details`}
                      </ShowDetailsRow>
                    ) : (
                      <ShowDetailsRow>
                        <MoreDetailsIcon />
                        {`See Details`}
                      </ShowDetailsRow>
                    )}
                  </span>
                </>
              </Col>
              <Col md={4}></Col>
              <Col md={8}>
                <div>
                  <CardFooter isUnitBooked={isUnitBooked} onClickAddUnit={onClickAddUnit} totalPax={unitAdultPax + unitChildPax} />
                </div>
              </Col>
            </Row>
            {/* <RoomInfo amenities={amenities} sleepingArrangements={sleepingArrangements} /> */}
          </Col>
        </Row>
        {isShowMoreDetails && (
          <Row>
            <SectionDivider />
            <Col span={24} md={24}>
              <RoomInfo amenities={amenities} sleepingArrangements={sleepingArrangements} />
            </Col>
          </Row>
        )}
        {isImageGalleryVisible && (
          <StyledModal
            width="90%"
            closeIcon={<StyledCloseButton onClick={handleOnCloseImageGallery} />}
            centered
            visible={isImageGalleryVisible}
            onCancel={handleOnCloseImageGallery}
            footer={null}
          >
            <div>
              <Carousel swipeToSlide={true} infinite={true} arrows={true} prevArrow={<PrevArrow />} nextArrow={<NextArrow />}>
                {images.length > 0 &&
                  images.map((photo, index) => (
                    <ImageContainer key={index}>
                      <StyledImage alt={`unit-detail-${index}`} src={photo.imageUrl} />
                      <span>
                        <StyledTextPropertyDetails>{photo.caption[0].text}</StyledTextPropertyDetails>
                      </span>
                    </ImageContainer>
                  ))}
              </Carousel>
            </div>
          </StyledModal>
        )}
      </div>
    );
  }
};

const HotelBody = ({
  stateMYSelection,
  roomType,
  property,
  checkIsUnitInBooking,
  onClickAddUnit,
  onClickAddMultipleUnits,
  checkInDate,
  checkOutDate,
  pax
}) => {
  const propAm = useRef(null);
  const propDetails = useRef(null);
  const houseRules = useRef(null);
  const { hostId } = useParams();
  const [isReadMore, setIsReadMore] = useState(true);
  const [isCopied, setIsCopied] = useState(false);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  let propertyAmenityList = [];
  if (property.propertyAmenities) {
    propertyAmenityList = property.propertyAmenities.split(', ');
  }
  // console.log(453, buildPropertyBaseUri(hostId, property._id));
  return (
    <>
      <Link to={buildHomePage(hostId)}>
        <BackButton icon={<LeftOutlined />}>Back to View All Property</BackButton>
      </Link>
      <Row gutter={{ md: 48 }} style={{ paddingBottom: 20 }}>
        <Col md={22}>
          <StyledTextUnitName>
            {property.bookingEngine && property.bookingEngine.displayName ? property.bookingEngine.displayName : property.name}
          </StyledTextUnitName>
        </Col>
        <Col>
          <CopyToClipboard
            text={window.location.href}
            onCopy={() => {
              setIsCopied(true);
              message.success('Link Copied!');
            }}
          >
            <ShareAltOutlinedIcon />
          </CopyToClipboard>
        </Col>
      </Row>

      <Row style={{ paddingBottom: 20 }}>
        <Col span={8}>
          <Button
            block={true}
            onClick={
              roomType.length > 0 && propDetails.current !== null
                ? () =>
                    propDetails.current.scrollIntoView({
                      behavior: 'smooth'
                    })
                : () => {}
            }
          >
            {' '}
            Property Details{' '}
          </Button>
        </Col>
        <Col span={8}>
          <Button
            block={true}
            onClick={
              roomType.length > 0 && propAm.current !== null
                ? () =>
                    propAm.current.scrollIntoView({
                      behavior: 'smooth'
                    })
                : () => {}
            }
          >
            {' '}
            Property Facilities{' '}
          </Button>
        </Col>
        <Col span={8}>
          <Button
            block={true}
            onClick={
              roomType.length > 0 && houseRules.current !== null
                ? () =>
                    houseRules.current.scrollIntoView({
                      behavior: 'smooth'
                    })
                : () => {}
            }
          >
            {' '}
            House Rules{' '}
          </Button>
        </Col>
      </Row>
      {property && property.bookingEngine && property.bookingEngine.propertyDetails && (
        <Row gutter={{ md: 48 }} style={{ paddingBottom: 20 }}>
          <Col span={24}>
            <Card title="Property Details" ref={propDetails}>
              {isReadMore ? (
                <StyledTextPropertyDetails>{property.bookingEngine.propertyDetails.slice(0, 150)}</StyledTextPropertyDetails>
              ) : (
                <StyledTextPropertyDetails>{property.bookingEngine.propertyDetails}</StyledTextPropertyDetails>
              )}
              {property.bookingEngine.propertyDetails.length > 150 && (
                <span onClick={toggleReadMore} className="read-or-hide">
                  {isReadMore ? <BackButton>{'...read more'}</BackButton> : <BackButton>{' show less'}</BackButton>}
                </span>
              )}
              {/* <StyledTextPropertyDetails>{property.bookingEngine.propertyDetails}</StyledTextPropertyDetails> */}
            </Card>
          </Col>
        </Row>
      )}
      {roomType.length > 0 ? (
        <Row>
          {roomType.map(rt => {
            const unitId = rt[1][0]._id;
            const roomTypeId = rt[1][0].roomType._id;
            const images = rt[1][0].roomType.images;
            const roomTypeName = rt[1][0].roomType.name;
            const bookingEngine = rt[1][0].roomType.bookingEngine;
            const unitCity = rt[1][0].roomType.property.city;
            const unitState = stateMYSelection.find(state => state.value === rt[1][0].roomType.property.state).label;
            const unitPricePerNight = rt[1][0].pricePerNight;
            const unitLength = rt[1].length;
            const adultPax = rt[1][0].roomType.capacity.adult;
            const childPax = rt[1][0].roomType.capacity.children;
            const minNight = rt[1][0].roomType.property.minNight;
            const maxNight = rt[1][0].roomType.property.maxNight;
            const isUnitBooked = checkIsUnitInBooking(unitId);
            // const extraGuest = bookingEngine.extraGuest;

            return (
              <CardContainer key={roomTypeId} span={24} md={24} xl={24}>
                <StyledCard>
                  {/* <Ribbon type="primary" text={unitLength + ' Unit(s) Left'}> */}
                  <CardBody
                    unitId={unitId}
                    roomTypeId={roomTypeId}
                    unitName={
                      bookingEngine === undefined || ({} && bookingEngine.externalDisplayName === undefined)
                        ? roomTypeName
                        : bookingEngine.externalDisplayName === undefined
                        ? roomTypeName
                        : bookingEngine.externalDisplayName
                    }
                    unitCity={unitCity}
                    unitState={unitState}
                    unitPricePerNight={unitPricePerNight}
                    unitAdultPax={adultPax}
                    unitChildPax={childPax}
                    unitLength={unitLength}
                    images={images}
                    minNight={minNight}
                    maxNight={maxNight}
                    isUnitBooked={isUnitBooked}
                    onClickAddUnit={onClickAddUnit(rt[1][0])}
                    onClickAddMultipleUnits={onClickAddMultipleUnits}
                    checkInDate={checkInDate}
                    checkOutDate={checkOutDate}
                    pax={pax}
                  />
                  {/* </Ribbon> */}
                </StyledCard>
              </CardContainer>
            );
          })}
        </Row>
      ) : (
        <StyledEmpty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No units available based on your search" />
      )}
      {property && property.propertyAmenities && (
        <Row gutter={{ md: 48 }} style={{ paddingBottom: 20 }}>
          <Col span={24}>
            <Card title="Property Amenities and Facilities" ref={propAm}>
              <ReadMore amenityList={propertyAmenityList} />
              {/* <StyledTextPropertyDetails>{property.bookingEngine.propertyDetails}</StyledTextPropertyDetails> */}
            </Card>
          </Col>
        </Row>
      )}
      {property && property.bookingEngine && (
        <Row gutter={{ md: 48 }} style={{ paddingBottom: 20 }}>
          <Col span={24}>
            <Card title="HouseRules" ref={houseRules}>
              {property.bookingEngine.nonSmoking && <StyledTextPropertyDetails>{'* No smoking allowed'}</StyledTextPropertyDetails>}
              {property.bookingEngine.cashDeposit && !property.bookingEngine.bankDeposit && (
                <StyledTextPropertyDetails>{'* Cash deposit required'}</StyledTextPropertyDetails>
              )}
              {property.bookingEngine.bankDeposit && !property.bookingEngine.cashDeposit && (
                <StyledTextPropertyDetails>{'* Bank deposit required'}</StyledTextPropertyDetails>
              )}
              {property.bookingEngine.bankDeposit && property.bookingEngine.cashDeposit && (
                <StyledTextPropertyDetails>{'* Cash/Bank deposit required'}</StyledTextPropertyDetails>
              )}
              <StyledTextPropertyDetails>{property.bookingEngine.houseRules}</StyledTextPropertyDetails>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};

export default HotelBody;
